package vegasful.admin.views

import androidx.compose.runtime.*
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Application
import vegasful.admin.admin
import vegasful.admin.api.EventTagInput
import vegasful.admin.api.EventUpdateInput
import vegasful.admin.api.client.*
import vegasful.admin.components.*

/**
 * The extensive event query.
 */
fun _EventQuery.eventQuery() {
    this.id
    this.name
    this.path
    this.description
    this.sourceUrl
    this.ticketsUrl
    this.duration
    this.time
    this.date
    this.performers {
        this.id
        this.name

        this.tags {
            this.id
            this.name
        }

        this.images {
            this.id
            this.uri
        }
    }
    this.venue {
        this.id
        this.name

        this.tags {
            this.id
            this.name
        }

        this.images {
            this.id
            this.uri
        }
    }
    this.tags {
        this.id
        this.name
    }
    this.images {
        this.id
        this.uri
    }
}

private fun _AdminEventLayerQuery.localQuery() {
    this.layerId
    this.name
    this.date
    this.time
    this.duration
    this.ticketsUrl
    this.sourceUrl
    this.description
    this.aliases
    this.lastUpdated
    this.excluded
    this.rankingScore
    this.suggestedPerformers
    this.tagline

    this.images {
        this.uri
        this.id
    }
    this.excludedImages {
        this.id
        this.uri
    }
    this.venue {
        this.id
        this.name
        this.path

        this.tags {
            this.id
            this.name
        }

        this.images {
            this.id
            this.uri
        }
    }
    this.performers {
        this.id
        this.name
        this.path

        this.tags {
            this.id
            this.name
        }

        this.images {
            this.id
            this.uri
        }
    }
    this.tags {
        this.id
        this.name
        this.path
    }
    this.excludedTags {
        this.id
        this.name
        this.path
    }
}

@Composable
fun event(eventId: String) {
    var layers by remember { mutableStateOf<List<AdminEventLayer>?>(null) }
    var parent by remember { mutableStateOf<Event?>(null) }
    var children by remember { mutableStateOf<List<Event>?>(null) }
    var baseLayer by remember { mutableStateOf<AdminEventLayer?>(null) }
    var overrideLayer by remember { mutableStateOf<AdminEventLayer?>(null) }
    var aiLayer by remember { mutableStateOf<AdminEventLayer?>(null) }

    LaunchedEffect(eventId) {
        layers = null
        parent = null
        children = null
        Application.mutate {
            admin {
                events {
                    event(eventId) {
                        this.id
                        layers {
                            localQuery()
                        }

                        this.parent {
                            eventQuery()
                        }

                        children {
                            eventQuery()
                        }
                    }
                }
            }
        }.admin.events.event.let { result ->
            parent = result.parent
            children = result.children.sortedBy { it.date }
            baseLayer = result.layers.find { it.layerId == "base" }
            aiLayer = result.layers.find { it.layerId == "ai" }
            overrideLayer = result.layers.find { it.layerId == "override" }

            layers = result.layers
        }
    }

    suspend fun updateEventOverride(update: EventUpdateInput) {
        Application.api.admin {
            events {
                event(eventId) {
                    layer("override") {
                        update(update) {
                            localQuery()
                        }
                    }
                }
            }
        }.events.event.layer?.update?.let {
            // we shouldn't have to do this, but the events API is returning stale data
            Application.mutate {
                admin {
                    events {
                        event(eventId) {
                            this.id
                            layers {
                                localQuery()
                            }
                        }
                    }
                }
            }.admin.events.event.let { result ->
                baseLayer = result.layers.find { it.layerId == "base" }
                overrideLayer = result.layers.find { it.layerId == "override" }
                layers = result.layers
            }
        }
    }

    /**
     * Run the enhancement job for the event.
     */
    suspend fun enhance() {
        Application.api.admin {
            events {
                enhance(eventId, true) {
                    this.name
                }
            }
        }.events.enhance


    }

    /**
     * Submit a review with the provided updaate
     */
    suspend fun submitReview(update: EventUpdateInput = EventUpdateInput()) {
        Application.api.admin {
            events {
                event(eventId) {
                    this.review(update)
                }
            }
        }.events.event.review.let { id ->
            if (id != null) {
                Application.navigation.navigate("content/events/${id}")
            } else {
                Application.navigation.navigate("content/events")
            }
        }
    }

    val resolvedVenue = ((listOfNotNull(overrideLayer?.venue)).ifEmpty { null }?.firstOrNull())
        ?: baseLayer?.venue
        ?: parent?.venue

    val resolvedPerformers = (overrideLayer?.performers
        ?: baseLayer?.performers ?: parent?.performers).orEmpty()

    contentContainer {
        entityView {
            title = layers?.firstNotNullOfOrNull { it.name }

            subTitle = eventId

            breadcrumbs {
                crumb("Events", "content/events") {
                    parent?.let {
                        crumb(it.name, it.id)
                    }
                }
            }

            action {
                title = "Next"
                primary = true
                showProgressOnAction = true
                action {
                    try {
                        submitReview()
                    } catch (e: Throwable) {
                        // ignored.
                    }
                }
            }

            content {
                box("Basics", {
                    if (parent == null) {
                        action {
                            this.title = "Enhance"
                            this.showProgressOnAction = true
                            this.action {
                                enhance()
                            }
                        }
                    }

                    if (overrideLayer?.excluded == true) {
                        action("Enable") {
                            updateEventOverride(EventUpdateInput(excluded = false))
                        }
                    } else {
                        action {
                            title = "Exclude"
                            showProgressOnAction = true
                            action {
                                submitReview(EventUpdateInput(excluded = true))
                            }
                        }
                    }
                }) {
                    var basicUpdate by remember { mutableStateOf(EventUpdateInput()) }
                    var updatedVenue: Venue? by remember { mutableStateOf(null) }
                    var venueCleared: Boolean by remember { mutableStateOf(false) }

                    overrideableField("Name", basicUpdate.name, overrideLayer?.name, baseLayer?.name ?: parent?.name) {
                        basicUpdate = basicUpdate.copy(name = it)
                    }

                    if (parent != null) {
                        dialogField("Parent") {
                            A(href = "#content/events/${parent?.id}") {
                                Text(parent?.name.orEmpty())
                            }
                        }
                    }

                    val inheritedVenue = baseLayer?.venue ?: parent?.venue
                    val setVenue = overrideLayer?.venue
                    dialogField(
                        "Venue",
                        instruction = if (setVenue != null && setVenue.id != inheritedVenue?.id) inheritedVenue?.name else null
                    ) {
                        if (venueCleared) {
                            venuesSearch({ true }) {
                                basicUpdate = basicUpdate.copy(venue = it.id)
                                updatedVenue = it
                                venueCleared = false
                            }
                        } else {
                            (updatedVenue ?: setVenue ?: inheritedVenue)?.let {
                                A(href = "#content/venues/${it.id}") {
                                    Text(it.name)
                                }
                                icon("delete") {
                                    action {
                                        venueCleared = true
                                        updatedVenue = null
                                    }
                                }
                            }
                        }
                    }

                    dialogField("Vegasful Score", "A value from 0-100 to indicate how 'Vegasful' the event is.") {
                        Input(InputType.Number) {
                            value(
                                basicUpdate.rankingScore?.toString() ?: overrideLayer?.rankingScore?.toString()
                                ?: aiLayer?.rankingScore?.toString() ?: ""
                            )
                            onInput {
                                basicUpdate = basicUpdate.copy(rankingScore = it.value?.toInt())
                            }
                        }
                    }

                    overrideableField(
                        "Tagline",
                        null,
                        null,
                        aiLayer?.tagline
                    ) {
//                            basicUpdate = basicUpdate.copy(tag = it ?: "")
                    }

                    overrideableRichEditor(
                        "Description", basicUpdate.description, overrideLayer?.description,
                        aiLayer?.description ?: baseLayer?.description ?: parent?.description
                    ) {
                        basicUpdate = basicUpdate.copy(description = it)
                    }

                    overrideableField(
                        "Tickets",
                        basicUpdate.ticketsUrl,
                        overrideLayer?.ticketsUrl,
                        baseLayer?.ticketsUrl ?: parent?.ticketsUrl
                    ) {
                        basicUpdate = basicUpdate.copy(ticketsUrl = it ?: "")
                    }

                    overrideableField(
                        "Page",
                        basicUpdate.sourceUrl,
                        overrideLayer?.sourceUrl,
                        baseLayer?.sourceUrl ?: parent?.sourceUrl
                    ) {
                        basicUpdate = basicUpdate.copy(sourceUrl = it)
                    }

                    if (listOfNotNull(
                            basicUpdate.name, basicUpdate.venue, basicUpdate.description, basicUpdate.ticketsUrl,
                            basicUpdate.sourceUrl,
                            basicUpdate.rankingScore
                        ).isNotEmpty()
                    ) {
                        Div {
                            button("Save", true) {
                                updateEventOverride(basicUpdate)
                                basicUpdate = EventUpdateInput()
                            }
                        }
                    }
                }

                sideBySide {
                    performersBox(
                        Application.api,
                        resolvedPerformers.map {
                            PerformersBoxPerformer(
                                it,
                                true
                            )
                        } + (aiLayer?.suggestedPerformers?.map {
                            PerformersBoxPerformer(null, false, it)
                        }.orEmpty().filter { suggested ->
                            !resolvedPerformers.any { it.name == suggested.suggestion }
                        }),
                        eventId,
                        baseLayer?.name,
                    ) { performer, operation ->
                        when (operation) {
                            PerformersOperation.ADD -> {
                                updateEventOverride(
                                    EventUpdateInput(
                                        performers = (overrideLayer?.performers.orEmpty().map { it.id } + performer.id)
                                            .distinct()
                                    ))
                            }

                            PerformersOperation.REMOVE -> {
                                updateEventOverride(
                                    EventUpdateInput(
                                        performers = (overrideLayer?.performers.orEmpty().map { it.id } - performer.id)
                                            .distinct()
                                    ))
                            }

                            else -> {}
                        }
                    }

                    val excludedTags = overrideLayer?.excludedTags?.map { it.id }.orEmpty().toMutableList()
                    val allTags = overrideLayer?.tags.orEmpty().map {
                        TagBoxTag(it)
                    } + baseLayer?.tags.orEmpty().map {
                        TagBoxTag(it, true, excludedTags.remove(it.id), "Base")
                    } + aiLayer?.tags.orEmpty().map {
                        TagBoxTag(it, true, excludedTags.remove(it.id), "AI")
                    } + resolvedPerformers.flatMap { performer ->
                        performer.tags.map {
                            TagBoxTag(it, true, excludedTags.remove(it.id), "Performer: ${performer.name}")
                        }
                    } + parent?.tags.orEmpty().map {
                        TagBoxTag(it, true, excludedTags.remove(it.id), "Parent")
                    } + (resolvedVenue?.let { venue ->
                        venue.tags.map {
                            TagBoxTag(it, true, excludedTags.remove(it.id), "Venue: ${venue.name}")
                        }
                    }).orEmpty() + (overrideLayer?.excludedTags?.filter {
                        excludedTags.contains(it.id)
                    }?.map { TagBoxTag(it, inherited = false, excluded = true) }.orEmpty())

                    tagsBox(allTags) { tag, operation ->
                        when (operation) {
                            TagOperation.ADD -> {
                                val newTags = (overrideLayer?.tags.orEmpty().map { it.id } + tag.id).distinct()
                                updateEventOverride(
                                    EventUpdateInput(
                                        eventTags = newTags.map { EventTagInput(it) }
                                    )
                                )
                            }

                            TagOperation.REMOVE -> {
                                val newTags = (overrideLayer?.tags.orEmpty().map { it.id } - tag.id).distinct()
                                updateEventOverride(
                                    EventUpdateInput(
                                        eventTags = newTags.map { EventTagInput(it) }
                                    )
                                )
                            }

                            TagOperation.EXCLUDE -> {
                                updateEventOverride(
                                    EventUpdateInput(
                                        excludedTags =
                                            (overrideLayer?.excludedTags?.map { it.id }?.toSet()
                                                .orEmpty() + tag.id).toList()
                                    )
                                )
                            }

                            TagOperation.UNEXCLUDE -> {
                                updateEventOverride(
                                    EventUpdateInput(
                                        excludedTags =
                                            (overrideLayer?.excludedTags?.map { it.id }?.toSet()
                                                .orEmpty() - tag.id).toList()
                                    )
                                )
                            }
                        }
                    }
                }

                val excludedIds = overrideLayer?.excludedImages?.map { it.id }.orEmpty()
                val allImages = (baseLayer?.images.orEmpty().map {
                    ImageBoxImage(it, false, true)
                }.filterNot { excludedIds.contains(it.image.id) }) + overrideLayer?.images.orEmpty().map {
                    ImageBoxImage(it, true, false)
                } + overrideLayer?.excludedImages.orEmpty().map {
                    ImageBoxImage(it, false, false, true)
                }
                imagesBox(allImages) { image, operation ->
                    when (operation) {
                        ImageBoxOperation.ADD -> {
                            updateEventOverride(
                                EventUpdateInput(
                                images = (overrideLayer?.images.orEmpty() + image).map { it.id }
                            ))
                        }

                        ImageBoxOperation.REMOVE -> {
                            updateEventOverride(
                                EventUpdateInput(
                                    images = overrideLayer?.images.orEmpty().map { it.id } - image.id
                                ))
                        }

                        ImageBoxOperation.EXCLUDE -> {
                            updateEventOverride(
                                EventUpdateInput(
                                    excludedImages = (overrideLayer?.excludedImages.orEmpty()
                                        .map { it.id } + image.id).distinct()
                                ))
                        }

                        ImageBoxOperation.ENABLE -> {
                            updateEventOverride(
                                EventUpdateInput(
                                    excludedImages = (overrideLayer?.excludedImages.orEmpty()
                                        .map { it.id } - image.id).distinct()
                                ))
                        }
                    }
                }

                eventsDateBox(overrideLayer, baseLayer, parent) {
                    updateEventOverride(it)
                }

                if (!children.isNullOrEmpty() && baseLayer != null) {
                    childEvents(children.orEmpty(), baseLayer!!)
                }
            }
        }
    }
}

@Composable
fun childEvents(children: List<Event>, baseLayer: AdminEventLayer) {
    val columns = HashSet<String>()
    val fieldRenderers = HashMap<String, @Composable (Event) -> Unit>()
    val widths = HashMap<String, CSSNumeric?>()

    fun checkChanged(title: String, value: String?, base: String?, width: CSSNumeric?, renderer: (Event) -> String?) {
        if (value != base) {
            columns += title
            fieldRenderers[title] = {
                val thisValue = renderer(it)
                if (thisValue != base) {
                    Text(thisValue.orEmpty())
                }
            }
            widths[title] = width
        }
    }

    children.forEach {
        checkChanged("Name", it.name, baseLayer.name, null) { it.name }
        checkChanged("Date", it.date, baseLayer.date, 100.px) { it.date }
        checkChanged("Time", it.time, baseLayer.time, 100.px) { it.time }
        checkChanged("Source", it.sourceUrl, baseLayer.sourceUrl, null) { it.sourceUrl }
        checkChanged("Description", it.description, baseLayer.description, null) { it.description }
        checkChanged("Tickets", it.ticketsUrl, baseLayer.ticketsUrl, 200.px) { it.ticketsUrl }
    }

    box({
        title = "Child events"
        paddedContent = false
    }) {
        table<Event> {
            items(children)
            if (columns.isEmpty()) {
                column("ID") {
                    content {
                        A(href = "#content/events/${it.id}") {
                            Text(it.id)
                        }
                    }
                }
            }
            columns.forEach { field ->
                column(field) {
                    width = widths[field]
                    content {
                        A(href = "#content/events/${it.id}") {
                            fieldRenderers[field]?.invoke(it)
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun overrideableField(
    title: String,
    updated: String?,
    overridden: String?,
    base: String?,
    onUpdate: (String?) -> Unit
) {
    dialogField(title, instruction = base?.takeIf { updated != null && it != updated }) {
        simpleTextField(updated ?: overridden ?: base) {
            if (it != null && it == (overridden ?: base)) {
                onUpdate(null)
            } else {
                onUpdate(it)
            }
        }
    }
}


@Composable
fun overrideableRichEditor(
    title: String,
    updated: String?,
    overridden: String?,
    base: String?,
    onUpdate: (String?) -> Unit
) {
    dialogField(title, instruction = base?.takeIf { updated != null && it != updated }) {
        richTextEditor(updated ?: overridden ?: base) {
            if (it != null && it == (overridden ?: base)) {
                onUpdate(null)
            } else {
                onUpdate(it)
            }
        }
    }
}

@Composable
fun eventsDateBox(
    overrideLayer: AdminEventLayer?,
    baseLayer: AdminEventLayer?,
    parent: Event?,
    save: suspend (EventUpdateInput) -> Unit
) {
    box("Date", {}) {
        var basicUpdate by remember { mutableStateOf(EventUpdateInput()) }

        sideBySideFields {
            overrideableField(
                "Date", basicUpdate.date, overrideLayer?.date, baseLayer?.date ?: parent?.date
            ) {

            }
            overrideableField(
                "Time", basicUpdate.time, overrideLayer?.time, baseLayer?.time ?: parent?.time
            ) {

            }

            overrideableField(
                "Duration", basicUpdate.duration?.toString(), overrideLayer?.duration?.toString(),
                baseLayer?.duration?.toString() ?: parent?.duration
            ) {

            }
        }

        if (listOfNotNull(basicUpdate.date, basicUpdate.time, basicUpdate.duration).isNotEmpty()) {
            Div {
                button("Save", true) {
                    save(basicUpdate)
                    basicUpdate = EventUpdateInput()
                }
            }
        }
    }
}