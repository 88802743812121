package vegasful.admin.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import org.jetbrains.compose.web.dom.Div

@Composable
fun raw(html: String) {
    Div {
        DisposableEffect(html) {
            scopeElement.innerHTML = html
            onDispose {}
        }
    }
}
