package vegasful.admin.loaders

import androidx.compose.runtime.*
import kotlinx.browser.localStorage
import kotlinx.datetime.Clock
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.LocalTime
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Application
import vegasful.admin.admin
import vegasful.admin.api.LoaderPublishInput
import vegasful.admin.api.client.Tag
import vegasful.admin.components.*
import vegasful.admin.views.venuesSearch

@Composable
fun loaderDataEditor(loaderId: String, lastLoad: List<LoaderEvent>?) {
    var events by remember { mutableStateOf(lastLoad.orEmpty()) }
    var tags by remember { mutableStateOf<Map<String, Tag>>(emptyMap()) }
    var expanded by remember { mutableStateOf<List<String>>(emptyList()) }

    LaunchedEffect(loaderId) {
        val loaderData = localStorage.getItem("loader-data-$loaderId")
        if (loaderData != null) {
            try {
                events = Json.decodeFromString(ListSerializer(LoaderEvent.serializer()), loaderData).sortedBy {
                    it.id
                }
            } catch (e: Throwable) {
                // do nothing
            }
        }
    }

    LaunchedEffect(events) {
        if (events.isNotEmpty()) {
            val str = Json.encodeToString(ListSerializer(LoaderEvent.serializer()), events)
            localStorage.setItem("loader-data-$loaderId", str)
        }
    }

    suspend fun submit(submitData: String) {
        try {
            Application.api.admin {
                loaders {
                    loader(loaderId) {
                        publish(LoaderPublishInput(submitData))
                    }
                }
            }.loaders.loader.publish
        } catch (e: Throwable) {
            Application.notifications.showError("Loader execution failed: ${e.message}")
        } finally {
        }
    }

    suspend fun saveEvents(events: List<LoaderEvent>) {
        val str = Json.encodeToString(ListSerializer(LoaderEvent.serializer()), events)
        submit(str)
    }


    box({
        title = "Events Editor"

        action {
            title = "Add Event"
            primary = true
            this.action {
                events = events + LoaderEvent(
                    "event-${Clock.System.now().epochSeconds}"
                )
            }
        }
        if (events.isNotEmpty()) {
            action {
                title = "Save"
                primary = true
                action {
                    saveEvents(events)
                }

            }

            action {
                title = "Clear"
                action {
                    saveEvents(emptyList())
                    events = emptyList()
                }
            }
        }
    }) {
        events.forEach { event ->
            Div {
                if (expanded.contains(event.id)) {
                    icon("expand_more") {
                        action {
                            expanded = expanded - event.id
                        }
                    }
                } else {
                    icon("chevron_right") {
                        this.action {
                            expanded = expanded + event.id
                        }
                    }
                }
                Text(event.id)
            }
            if (expanded.contains(event.id)) {
                eventEditor(event) { update ->
                    if (update == null) {
                        events = events - event
                    } else {
                        if (event.id != update.id) {
                            expanded = expanded - event.id + update.id
                        }
                        events = events.replace(event, update)
                    }
                }
            }
        }
    }
}

@Composable
fun eventEditor(event: LoaderEvent, editable: Boolean = true, updater: (LoaderEvent?) -> Unit) {
    var updated by remember { mutableStateOf(event) }

    LaunchedEffect(updated) {
        updater(updated)
    }

    box({
        if (editable) {
            action {
                title = "Remove"
                primary = false
                action {

                }
            }

            action {
                title = "Add Schedule"
                primary = true
                action {
                    updated = updated.copy(schedule = updated.schedule.orEmpty() + ScheduleDescriptor(emptyList()))
                }
            }
        }
    }) {
        dialogField("ID") {
            simpleTextField(updated.id) {
                if (it != null) {
                    updated = updated.copy(id = it)
                }
            }
        }

        if (updated.name != null || editable) {
            dialogField("Name") {
                simpleTextField(updated.name) {
                    updated = updated.copy(name = it)
                }
            }
        }

        val venue = updated.venue
        if (venue != null || editable) {
            dialogField("Venue") {
                if (editable) {
                    updated.venue?.id?.let {
                        Div {
                            Text(it)
                        }
                    }
                    venuesSearch({ true }) {
                        updated = updated.copy(venue = LoaderVenue(it.id))
                    }
                } else if (venue != null) {
                    if (venue.id != null) {
                        Text(venue.id)
                    } else {
                        val aliases = (venue.aliases ?: listOfNotNull(venue.alias)).orEmpty()
                        Text("alias: ${aliases.joinToString()}")
                    }
                }
            }
        }

        if (updated.description != null || editable) {
            dialogField("Description") {
                richTextEditor(updated.description) {
                    updated = updated.copy(description = it)
                }
            }
        }

        if (updated.name != null || editable) {
            dialogField("Image") {
                simpleTextField(updated.images?.firstOrNull()?.url) {
                    updated = updated.copy(
                        images = if (it != null) {
                            listOf(
                                LoaderImage(url = it)
                            )
                        } else emptyList()
                    )
                }
            }
        }

        if (updated.ticketsUrl != null || editable) {
            dialogField("Tickets") {
                simpleTextField(updated.ticketsUrl) {
                    updated = updated.copy(ticketsUrl = it)
                }
            }
        }

        if (updated.sourceUrl != null || editable) {
            dialogField("Source URL") {
                simpleTextField(updated.sourceUrl) {
                    updated = updated.copy(sourceUrl = it)
                }
            }
        }

        if (updated.tags != null || editable) {
            dialogField("Tags", "Comma separated list of tag ids") {
                simpleTextField(updated.tags.orEmpty().joinToString(",")) {
                    if (it != null) {
                        updated = updated.copy(tags = it.split(",").filter { it.isNotBlank() })
                    } else {
                        updated = updated.copy(tags = null)
                    }
                }
            }
        }

        updated.schedule?.forEach { schedule ->
            scheduleEditor(schedule) {
                if (it == null) {
                    updated = updated.copy(schedule = updated.schedule.orEmpty() - schedule)
                } else {
                    updated = updated.copy(schedule = updated.schedule.orEmpty().replace(schedule, it))
                }
            }
        }
    }
}

/**
 * Editor for a manually entered schedule
 */
@Composable
fun scheduleEditor(schedule: ScheduleDescriptor, update: (ScheduleDescriptor?) -> Unit) {
    var updated by remember { mutableStateOf(schedule) }
    var timesInput by remember { mutableStateOf<String?>(schedule.times.joinToString(",")) }

    LaunchedEffect(timesInput) {
        try {
            updated = updated.copy(times = timesInput?.split(",")?.map {
                LocalTime.parse(it)
            }.orEmpty())
        } catch (e: IllegalArgumentException) {
            // do nothing
        }
    }

    LaunchedEffect(updated) {
        update(updated)
    }

    fun ScheduleDescriptor.isRepeating(): Boolean {
        return (repeatFor ?: 0) > 0 || endDate != null
    }

    box({
        title = "Schedule"
    }) {
        Div({
            style {
                display(DisplayStyle.Flex)
                gap(20.px)
            }
        }) {
            dialogField("Start Date", "The start of events. If empty, starts immediately.") {
                dateField(updated.startDate) {
                    updated = updated.copy(startDate = it)
                }
            }
            dialogField("Repeat Days", "The number of days from today to schedule.") {
                numberField(updated.repeatFor) {
                    updated = updated.copy(repeatFor = (it ?: 0).toInt())
                }
            }
            dialogField("Repeat End", "The date that repeats end.") {
                dateField(updated.endDate) {
                    updated = updated.copy(endDate = it)
                }
            }
        }

        dialogField("Times", "Enter times in 24hr format. ie. 20:45, included padded hours (04)") {
            simpleTextField(timesInput) {
                timesInput = it
            }
        }

        if (updated.isRepeating()) {
            dialogField("Repeating Days") {
                DayOfWeek.values().forEach { dow ->
                    simpleCheckBox(dow.name, updated.daysOfWeek.contains(dow.ordinal)) {
                        val updatedDays = if (it) {
                            updated.daysOfWeek + dow.ordinal
                        } else {
                            updated.daysOfWeek - dow.ordinal
                        }
                        updated = updated.copy(daysOfWeek = updatedDays)
                    }
                }
            }
        }
    }
}

fun <T> List<T>.replace(value: T, replacement: T): List<T> {
    return this.map {
        if (value == it) {
            replacement
        } else {
            it
        }
    }
}