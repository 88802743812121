package vegasful.admin.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Theme

object DetailBoxStyles : StyleSheet() {
    val detailBox by style {
        display(DisplayStyle.Flex)
        gap(10.px)
    }

    val detailContent by style {
        marginBottom(10.px)
    }

    val detailLabel by style {
        fontSize(11.px)
        color(Theme.subtleTextColor.value())
        marginBottom(3.px)
    }

    val detailTextContent by style {
        fontSize(14.px)
    }
}

interface DetailGridConfig {
}

@Composable
fun DetailGridConfig.stat(title: String, value: Int, showOnlyIfNonZero: Boolean = false) {
    if (showOnlyIfNonZero && value == 0) return

    detail(title) {
        Div({
            style {
                fontSize(25.px)
            }
        }) {
            Text(value.toString())
        }
    }
}

@Composable
fun DetailGridConfig.detail(title: String, value: String?) {
    detail(title) {
        Div({ classes(DetailBoxStyles.detailTextContent) }) {
            Text(value ?: "-")
        }
    }
}


@Composable
fun DetailGridConfig.detail(title: String, content: @Composable () -> Unit) {
    Div({ classes(DetailBoxStyles.detailContent) }) {
        Div({
            classes(DetailBoxStyles.detailLabel)
        }) {
            Text(title)
        }
        Div {
            content()
        }
    }
}


/**
 * A grid of information, each with a label
 */
@Composable
fun detailGrid(config: @Composable DetailGridConfig.() -> Unit) {
    Div({ classes(DetailBoxStyles.detailBox) }) {
        object : DetailGridConfig {}.config()
    }
}