package vegasful.admin.loaders

import androidx.compose.runtime.*
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.dom.*
import vegasful.admin.Application
import vegasful.admin.admin
import vegasful.admin.api.LoaderConfiguration
import vegasful.admin.api.LoaderExecutionInput
import vegasful.admin.api.client.AdminLoader
import vegasful.admin.api.client.AdminLoaderModule
import vegasful.admin.api.client._AdminLoaderQuery
import vegasful.admin.components.*
import vegasful.admin.views.contentContainer

fun _AdminLoaderQuery.default() {
    id
    name
    schedule
    parameters
    module {
        id
        this.name
    }
}

@Composable
fun loader(loaderId: String?) {
    val scope = rememberCoroutineScope()
    var modules by remember { mutableStateOf<List<AdminLoaderModule>?>(null) }
    var loader by remember { mutableStateOf<AdminLoader?>(null) }
    var executeData by remember { mutableStateOf<String?>(null) }
    var executing by remember { mutableStateOf(false) }
    var lastLoaded by remember { mutableStateOf<List<LoaderEvent>?>(null) }

    /**
     * Run the loader with optional input.
     */
    suspend fun execute(data: String?) {
        if (loaderId == null) {
            return
        }
        executing = true
        try {
            Application.api.admin {
                loaders {
                    loader(loaderId) {
                        execute(LoaderExecutionInput(data))
                    }
                }
            }.loaders.loader.execute
        } catch (e: Throwable) {
            Application.notifications.showError("Loader execution failed: ${e.message}")
        } finally {
            executing = false
        }
    }

    /**
     * Run the loader with optional input.
     */
    suspend fun reprocess() {
        if (loaderId == null) {
            return
        }
        executing = true
        try {
            Application.api.admin {
                loaders {
                    loader(loaderId) {
                        reprocess
                    }
                }
            }.loaders.loader.reprocess
        } catch (e: Throwable) {
            Application.notifications.showError("Loader execution failed: ${e.message}")
        } finally {
            executing = false
        }
    }

    LaunchedEffect(loaderId) {
        Application.api.admin {
            loaders {
                modules {
                    this.id
                    this.name
                }
                if (loaderId != null) {
                    loader(loaderId) {
                        default()
                        lastLoad {
                            ts
                            events
                        }
                    }
                }
            }
        }.loaders.let {
            if (loaderId != null) {
                loader = it.loader

                try {
                    it.loader.lastLoad?.let {
                        lastLoaded = Json.decodeFromString<List<LoaderEvent>?>(it.events)?.sortedBy {
                            it.id
                        }
                    }
                } catch (t: Throwable) {
                    // ignored.
                }
            }
            modules = it.modules.sortedBy { it.name.lowercase() }
        }
    }

    contentContainer {
        entityView {
            title = if (loaderId == null) "New Loader" else loader?.name
            breadcrumbs {
                crumb("Loaders", "content/loaders")
            }

            if (loaderId != null) {
                action {
                    title = "Delete"
                    action {
                        Application.api.admin {
                            loaders {
                                this.deregister(loaderId)
                            }
                        }.loaders.deregister.let {
                            Application.navigation.navigate("content/loaders")
                        }
                    }
                }

                action {
                    title = "Execute"
                    primary = true
                    showProgressOnAction = true
                    action {
                        execute(null)
                    }
                }
                action {
                    title = "Reprocess"
                    primary = true
                    showProgressOnAction = true
                    action {
                        reprocess()
                    }
                }
            }

            content {
                box({

                }) {
                    var newId by remember { mutableStateOf<String?>(null) }
                    var update by remember { mutableStateOf(LoaderConfiguration(name = loader?.name.orEmpty())) }

                    dialogField("ID") {
                        Input(InputType.Text) {
                            if (loaderId != null) {
                                disabled()
                            }
                            value((loaderId ?: newId).orEmpty())
                            onInput {
                                newId = it.value.ifBlank { null }
                            }
                        }
                    }

                    dialogField("Name") {
                        simpleTextField(update.name) {
                            update = update.copy(name = it.orEmpty())
                        }
                    }

                    dialogField("Schedule") {
                        simpleTextField(update.schedule ?: loader?.schedule) {
                            update = update.copy(schedule = it ?: "")
                        }
                    }

                    dialogField("Module") {
                        Select({
                            onChange {
                                update = update.copy(module = if (it.value == "none") "" else it.value)
                            }
                        }) {
                            Option("none", {
                                if (loader?.module == null) selected()
                            }) {
                                Text("No loader module")
                            }
                            modules?.forEach { module ->
                                Option(module.id, {
                                    if (module.id == loader?.module?.id) selected()
                                }) {
                                    Text(module.name)
                                }
                            }
                        }
                    }

                    if (loader?.module?.id != "ManualLoader") {
                        dialogField("Parameters") {
                            TextArea {
                                style {
                                    height(3.cssRem)
                                }
                                value(update.parameters ?: loader?.parameters ?: "")
                                onInput {
                                    update = update.copy(
                                        parameters = it.value.takeIf { it.isNotBlank() && it != loader?.parameters }
                                    )
                                }
                            }
                        }
                    }

                    Div {
                        button("Save", true, true) {
                            if (loaderId == null) {
                                if (!newId.isNullOrBlank()) {
                                    try {
                                        Application.api.admin {
                                            loaders {
                                                registerLoader(newId!!, update) {
                                                    default()
                                                }
                                            }
                                        }.loaders.registerLoader.let {
                                            Application.navigation.navigate("content/loaders/${it.id}")
                                        }
                                    } catch (e: Throwable) {
                                        Application.notifications.showError("The loader wasn't created")
                                    }
                                } else {
                                    Application.notifications.showError("You must enter a valid id")
                                }
                            } else {
                                Application.api.admin {
                                    loaders {
                                        this.loader(loaderId) {
                                            this.update(update) {
                                                default()
                                            }
                                        }
                                    }
                                }.loaders.loader.update.let {
                                    Application.navigation.navigate("content/loaders/${it.id}")
                                }
                            }
                        }

                        if (loaderId != null) {
                            button("Revert") {
                                update = LoaderConfiguration(name = loader?.name.orEmpty())
                            }
                        }
                    }
                }

                if (loaderId != null) {
                    val loaderModule = loader?.module
                    if (loaderModule?.id == "ManualLoader") {
                        loaderDataEditor(loaderId, lastLoaded)
                    } else {
                        if (lastLoaded != null) {
                            box("Last Load", {}) {
                                eventsViewer(lastLoaded.orEmpty())
                            }
                        }
                    }
                }
            }
        }
    }
}