package vegasful.admin.views

import androidx.compose.runtime.*
import com.steamstreet.graphkt.client.GraphQLClient
import com.steamstreet.graphkt.client.GraphQLClientException
import io.ktor.http.*
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.value
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Application
import vegasful.admin.Theme
import vegasful.admin.admin
import vegasful.admin.api.GlobalSearchInput
import vegasful.admin.api.PerformerUpdateInput
import vegasful.admin.api.client.Performer
import vegasful.admin.api.client.query
import vegasful.admin.components.*
import vegasful.admin.simpleSlug

/**
 * Encapsulates an item in the performers box. Can be an actual performer
 * or a suggestion of a performer.
 */
class PerformersBoxPerformer(
    val performer: Performer?,
    val removable: Boolean,
    val suggestion: String? = null
)

enum class PerformersOperation {
    ADD,
    REMOVE,
    EXCLUDE
}

enum class SearchStyle {
    PILL,
    BOX
}

@Composable
fun performersBox(
    api: GraphQLClient,
    performers: List<PerformersBoxPerformer>,
    eventId: String? = null,
    initialSearch: String? = null,
    operation: suspend (Performer, PerformersOperation) -> Unit
) {
    val coroutine = rememberCoroutineScope()
    var adding by remember { mutableStateOf(initialSearch != null) }
    var operatingOn by remember { mutableStateOf<String?>(null) }

    suspend fun addSuggestion(suggestion: String) {
        val slug = suggestion.simpleSlug()
        var performer: Performer?

        // look up the performer by id, make sure it's not already there
        performer = try {
            api.query {
                performers {
                    performer(slug) {
                        id
                        name
                        path
                    }
                }
            }.performers.performer
        } catch (e: GraphQLClientException) {
            null
        }

        if (performer == null) {
            performer = api.query {
                search(GlobalSearchInput(suggestion)) {
                    performers {
                        this.id
                        this.name
                    }
                }
            }.search.performers?.find {
                it.name == suggestion
            }
        }

        if (performer == null) {
            try {
                // no matches, so we'll create the performer and run enhancement
                api.admin {
                    performers {
                        add(slug, PerformerUpdateInput(name = suggestion)) {
                            id
                        }
                    }
                }.performers.add

                api.admin {
                    performers {
                        enhance(slug, true) {
                            name
                            description
                            tags
                            wikiIdentifier
                        }
                    }
                }
                performer = api.query {
                    performers {
                        performer(slug) {
                            id
                            name
                        }
                    }
                }.performers.performer
            } catch (e: Throwable) {
                Application.notifications.showError(e.message ?: "Unable to create a new performer")
            }
        }

        if (performer != null) {
            operation(performer, PerformersOperation.ADD)
        }
    }

    box({
        title = "Performers"
        paddedContent = false

        action("Add", true) {
            adding = true
        }
    }) {
        Div({
            style {
                borderBottom(1.px, color = Theme.tabsBorder.value())
            }
        }) {
            if (performers.isEmpty()) {
                boxMessage("No performers.")
            } else {

                table<PerformersBoxPerformer> {
                    items(performers)
                    column {
                        content {
                            if (it.performer != null) {
                                A(href = "#content/performers/${it.performer.id}") {
                                    Text(it.performer.name)
                                }
                            } else if (it.suggestion != null) {
                                Text(it.suggestion)
                            }
                        }
                    }

                    column {
                        width = 100.px
                        content { performer ->
                            Div({
                                style { textAlign("right") }
                            }) {
                                if (operatingOn != null && (operatingOn == performer.performer?.id
                                            || operatingOn == performer.suggestion)
                                ) {
                                    spinner()
                                } else if (performer.suggestion != null) {
                                    icon("add") {
                                        action {
                                            coroutine.launch {
                                                operatingOn = performer.suggestion
                                                addSuggestion(performer.suggestion)
                                                operatingOn = null
                                            }
                                        }
                                    }
                                } else {
                                    if (performer.performer != null && performer.removable) {
                                        icon("delete") {
                                            action {
                                                coroutine.launch {
                                                    operatingOn = performer.performer.id
                                                    operation(performer.performer, PerformersOperation.REMOVE)
                                                    operatingOn = null
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if (adding) {
            entitySearch<Performer> {
                withEntityResult {
                    performers {
                        id
                        name
                        path
                    }
                }
                selection = {
                    operation(it, PerformersOperation.ADD)
                }
                this.initialSearchInput = initialSearch
                showSpinnerOnClick = true

                add = { searchInput ->
                    Application.navigation.navigate {
                        path("content", "performers", "add")
                        if (eventId != null) {
                            parameters.append("event", eventId)
                        }
                        if (!searchInput.isNullOrBlank()) {
                            parameters.append("name", searchInput)
                        }
                    }
                }

                onClose = {
                    adding = false
                }
            }
        }
    }
}