package vegasful.admin.views

import androidx.compose.runtime.*
import io.ktor.http.*
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.Input
import vegasful.admin.Application
import vegasful.admin.admin
import vegasful.admin.api.EventUpdateInput
import vegasful.admin.api.PerformerUpdateInput
import vegasful.admin.api.client.*
import vegasful.admin.components.*
import vegasful.admin.simpleSlug

fun _AdminPerformerLayerQuery.performerLayerQuery() {
    name
    description
    wikiIdentifier
    rankingScore
    this.images {
        imageThumbnailQuery()
    }

    tags {
        id
        name
        path
    }
}


suspend fun addPerformerToEvent(id: String, eventId: String) {
    // first get the existing list
    val existing = Application.api.admin {
        events {
            event(eventId) {
                layer("override") {
                    performers {
                        this.id
                    }
                }
            }
        }
    }.events.event.layer!!.performers?.map {
        it.id
    }.orEmpty()

    if (existing.contains(id)) {
        return
    }

    Application.api.admin {
        events {
            event(eventId) {
                layer("override") {
                    update(EventUpdateInput(performers = existing + id)) {
                        this.name
                    }
                }
            }
        }
    }.events.event.layer!!.update.name
}

@Composable
fun performer(performerId: String?, eventId: String? = null, initialName: String? = null) {
    var performer by remember { mutableStateOf<AdminPerformer?>(null) }
    var local by remember { mutableStateOf<AdminPerformerLayer?>(null) }
    var update by remember { mutableStateOf(PerformerUpdateInput()) }

    var newId by remember { mutableStateOf(performerId ?: initialName?.simpleSlug()) }

    var images by remember { mutableStateOf(emptyList<Image>()) }
    var tags by remember { mutableStateOf(emptyList<Tag>()) }

    suspend fun loadPerformer() {
        if (performerId != null) {
            Application.mutate {
                admin {
                    performers {
                        performer(performerId) {
                            this.id
                            layer("base") {
                                performerLayerQuery()
                            }
                        }
                    }
                }
            }.admin.performers.performer.let {
                performer = it
                local = it.layer
                tags = it.layer?.tags.orEmpty()
                images = it.layer?.images.orEmpty()
            }
        }
    }

    suspend fun updatePerformer(update: PerformerUpdateInput) {
        Application.mutate {
            admin {
                performers {
                    performer(performerId!!) {
                        layer("base") {
                            update(update) {
                                performerLayerQuery()
                            }
                        }
                    }
                }
            }
        }.admin.performers.performer.layer?.update.let {
            loadPerformer()
        }
    }

    /**
     * Add a new performer. Will redirect to this page.
     */
    suspend fun addPerformer(id: String, update: PerformerUpdateInput) {
        Application.mutate {
            admin {
                performers {
                    add(id, update) {
                        this.id
                    }
                }
            }
        }.admin.performers.add.id.let {
            if (eventId != null) {
                addPerformerToEvent(it, eventId)
                Application.navigation.navigate("content/events/${eventId}")
            } else {
                Application.navigation.navigate {
                    path("content", "performers", id)

                }
            }
        }
    }

    suspend fun enhance() {
        if (performerId != null) {
            Application.api.admin {
                performers {
                    enhance(performerId, false) {
                        description
                        wikiIdentifier
                        this.tags
                    }
                }
            }.performers.enhance?.let {
                update = update.copy(description = it.description,
                    wikiIdentifier = it.wikiIdentifier,
                    tags = it.tags
                )

                tags = it.tags?.map {
                    Application.api.query {
                        tags {
                            tag(it) {
                                this.id
                                this.name
                                this.path
                            }
                        }
                    }.tags.tag
                }.orEmpty()
            }
        }
    }

    /**
     * Save the general information
     */
    suspend fun save() {
        if (performerId != null) {
            updatePerformer(update)
        } else {
            addPerformer(newId!!, update)
        }
    }

    LaunchedEffect(performerId) {
        local = null
        loadPerformer()
    }

    contentContainer {
        dialogView(if (performerId == null) "New Performer" else local?.name) {
            breadcrumbs {
                crumb("Performers", "content/performers")
            }

            if (eventId != null) {
                action("Cancel") {
                    Application.navigation.navigate("content/events/${eventId}")
                }
            }

            content {
                box({}) {
                    dialogField("ID") {
                        Input(InputType.Text) {
                            if (performerId != null) {
                                disabled()
                                defaultValue(newId.orEmpty())
                            }
                            value(newId.orEmpty())
                            this.onInput {
                                newId = it.value
                            }
                        }
                    }

                    dialogField("Name") {
                        simpleTextField(update.name ?: local?.name) {
                            update = update.copy(name = it?.takeIf { it != local?.name })
                        }
                    }

                    dialogField("Description") {
                        richTextEditor(update.description ?: local?.description ?: "") {
                            update = update.copy(description = it?.takeIf { it != local?.description })
                        }
                    }

                    dialogField("Wikipedia ID") {
                        simpleTextField(update.wikiIdentifier ?: local?.wikiIdentifier) {
                            update = update.copy(wikiIdentifier = it?.takeIf { it != local?.wikiIdentifier })
                        }
                    }

                    dialogField("Ranking Score") {
                        numberField(update.rankingScore ?: local?.rankingScore) {
                            update = update.copy(rankingScore = it?.takeIf { it in 1..100 }?.toInt())
                        }
                    }

                }

                // don't allow editing on initial creation.
                imagesBox(images.map { ImageBoxImage(it) }) { image, operation ->
                    var newImages: List<Image>? = null
                    when (operation) {
                        ImageBoxOperation.ADD -> {
                            newImages = images + image
                        }

                        ImageBoxOperation.REMOVE -> {
                            newImages = images.filterNot { it.id == image.id }
                        }

                        ImageBoxOperation.EXCLUDE -> {}
                        ImageBoxOperation.ENABLE -> {}
                    }
                    if (newImages != null) {
                        images = newImages
                        update = update.copy(images = newImages.map { it.id })
                    }
                }

                tagsBox(tags.map { TagBoxTag(it) }) { tag, operation ->
                    var newTagsList: List<Tag>? = null
                    when (operation) {
                        TagOperation.ADD -> {
                            newTagsList = tags + tag
                        }

                        TagOperation.REMOVE -> {
                            newTagsList = tags.filterNot { it.id == tag.id }
                        }

                        else -> {

                        }
                    }
                    if (newTagsList != null) {
                        tags = newTagsList
                        update = update.copy(tags = newTagsList.map { it.id })
                    }
                }
            }

            action {
                title = "Save"
                primary = true
                showProgressOnAction = true
                action {
                    save()
                }
            }
            action {
                title = "Enhance"
                primary = false
                showProgressOnAction = true
                action {
                    enhance()
                }
            }
        }
    }
}